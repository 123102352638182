import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" >
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M235.675339,291.714722 
	C203.799179,285.696350 181.135956,268.161530 168.309326,239.012085 
	C145.667145,187.556137 174.356201,125.585541 233.106628,114.105141 
	C284.736877,104.016098 330.799805,136.203842 339.971710,186.089706 
	C349.454712,237.667511 315.695404,284.014008 264.855316,292.122314 
	C255.359268,293.636780 245.666550,293.379883 235.675339,291.714722 
M215.343201,140.881271 
	C189.530579,155.549683 174.934128,186.133728 180.616623,215.104477 
	C188.162674,253.576187 222.749420,279.132172 261.501129,273.414429 
	C301.595673,267.498627 327.743195,229.447723 321.809479,192.267166 
	C313.840942,142.336853 259.647369,115.673927 215.343201,140.881271 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M166.129074,338.617798 
	C162.211777,344.791260 156.053467,346.963684 148.548416,344.934967 
	C144.133057,343.741425 140.634445,341.542023 140.741516,336.472961 
	C142.020233,334.642151 144.148300,335.586792 145.495789,334.158630 
	C140.305466,329.347870 137.050735,323.914703 139.982910,316.561279 
	C141.515121,312.718719 144.093597,310.119720 148.216599,309.382812 
	C153.928940,308.361847 159.548965,310.864166 165.308929,309.942047 
	C166.619370,309.732239 167.198700,311.203857 167.163574,312.443085 
	C166.919983,321.041931 168.356735,329.700378 166.129074,338.617798 
M156.910324,323.614960 
	C158.267960,320.947113 157.028595,319.133545 154.779205,317.929718 
	C152.923492,316.936615 151.162094,317.655823 150.014191,319.274628 
	C148.721573,321.097504 148.859497,323.121277 150.500412,324.647461 
	C152.535980,326.540710 154.655640,326.275696 156.910324,323.614960 
M151.771286,334.701111 
	C149.593475,335.460999 150.172791,336.659546 151.674530,337.215271 
	C154.089188,338.108765 155.945663,337.410431 156.588364,334.101135 
	C154.879517,334.283722 153.612717,334.419067 151.771286,334.701111 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M90.330223,327.113434 
	C90.483711,331.384155 90.838745,335.034912 84.935036,334.460815 
	C81.684944,334.144714 80.495979,333.019409 80.665840,329.897308 
	C80.801407,327.405273 80.676903,324.899078 80.667503,322.399170 
	C80.659637,320.305573 80.447990,317.940826 77.895866,317.862946 
	C75.196037,317.780579 74.475670,320.129181 74.386848,322.443024 
	C74.284622,325.106171 74.195961,327.783569 74.354469,330.439667 
	C74.572144,334.086945 72.411530,334.423035 69.591568,334.378571 
	C66.878922,334.335846 64.590355,334.242249 64.680298,330.527588 
	C64.817406,324.864563 64.772736,319.194824 64.685188,313.529755 
	C64.643158,310.810486 65.759666,309.110626 68.485481,309.757385 
	C74.527985,311.191010 80.968651,306.680542 86.821526,311.177277 
	C87.894608,312.001740 89.269348,310.531738 90.492775,310.082306 
	C99.731667,306.688538 106.168076,311.035339 106.324577,320.795746 
	C106.367325,323.461670 106.254089,326.131592 106.350121,328.794708 
	C106.468819,332.086731 106.277855,334.495758 101.726944,334.490082 
	C97.220528,334.484497 96.349419,332.471313 96.636734,328.696960 
	C96.813248,326.378204 96.676926,324.032532 96.623070,321.700317 
	C96.576714,319.692657 95.877113,317.804413 93.635872,317.830688 
	C91.515358,317.855560 90.573128,319.646759 90.423035,321.651672 
	C90.298866,323.310242 90.354568,324.982300 90.330223,327.113434 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M223.407318,310.222717 
	C230.186020,314.785828 232.056458,321.608124 228.760880,328.655334 
	C226.233490,334.059814 220.030060,336.384644 212.942963,334.257385 
	C210.876343,333.637085 209.138824,333.982391 207.178894,334.355499 
	C203.770462,335.004303 201.464737,334.316589 201.597809,329.878876 
	C201.852036,321.402039 201.759033,312.910675 201.621002,304.428375 
	C201.568192,301.182465 202.384521,299.508453 206.053116,299.600311 
	C209.308411,299.681854 211.744705,300.036926 211.301300,304.123962 
	C210.850952,308.274933 211.789398,310.675293 216.758133,308.972717 
	C218.849976,308.255951 220.997208,309.400635 223.407318,310.222717 
M215.634689,317.618164 
	C212.478226,318.702148 210.186783,320.392609 212.053406,324.101074 
	C212.882751,325.748688 214.480850,326.873596 216.526566,326.407135 
	C218.611221,325.931854 220.193802,324.540161 220.375366,322.423157 
	C220.608139,319.709167 218.675919,318.375488 215.634689,317.618164 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M434.729309,334.346985 
	C428.112305,335.906403 426.299011,330.687500 423.994873,326.940826 
	C422.398193,327.690308 422.311829,328.598877 422.403076,329.380035 
	C422.930145,333.893066 420.422974,334.453003 416.676025,334.438965 
	C412.163971,334.422089 412.663391,331.519470 412.663025,328.696320 
	C412.661896,320.549957 412.826965,312.399536 412.612762,304.258972 
	C412.508423,300.293732 414.375580,299.617340 417.770203,299.593262 
	C421.260681,299.568451 422.519958,300.866974 422.352417,304.293793 
	C422.151428,308.405487 422.304932,312.534485 422.304932,318.340942 
	C427.311920,313.799835 429.661224,306.159760 437.890106,310.808655 
	C436.081970,317.906891 430.187927,325.856598 438.812378,332.200439 
	C437.905884,334.773895 436.366150,334.146942 434.729309,334.346985 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M123.919510,335.216370 
	C115.074707,334.099731 110.838089,330.991028 109.796104,325.174805 
	C108.659462,318.830261 110.769035,313.672699 115.608322,310.965088 
	C121.319489,307.769592 128.923737,308.398529 133.339417,312.422272 
	C137.701004,316.396729 138.139145,321.345490 136.537033,326.576111 
	C135.228287,330.848999 132.198822,333.533264 127.746132,334.471741 
	C126.610313,334.711151 125.476479,334.959930 123.919510,335.216370 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M196.386185,317.067749 
	C197.630615,321.560760 196.909134,325.741608 197.161514,329.867188 
	C197.402969,333.814331 195.263794,334.951813 191.915543,334.288116 
	C189.248581,333.759430 186.819839,333.829742 184.197342,334.697449 
	C179.909241,336.116302 175.826416,335.255157 172.902283,331.692200 
	C170.127640,328.311371 170.505447,324.635468 173.112030,321.178589 
	C173.617477,320.508270 173.852722,319.868683 173.712967,318.919403 
	C172.901733,313.408691 174.345123,311.206421 179.572266,309.666107 
	C187.570801,307.309052 193.549179,309.801178 196.386185,317.067749 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M254.787979,310.891846 
	C261.514099,316.157074 262.983032,322.664307 259.182648,328.992310 
	C255.610641,334.940063 246.349319,337.014526 239.123718,333.485352 
	C233.286011,330.634064 230.638687,323.835815 232.865021,317.413147 
	C234.887192,311.579468 241.958359,307.789062 248.726593,309.042480 
	C250.667740,309.401947 252.537308,310.147858 254.787979,310.891846 
M242.409943,321.037476 
	C242.753357,325.394592 244.792542,327.334686 247.900558,326.221375 
	C249.759888,325.555328 250.936844,324.225128 251.105530,322.239838 
	C251.280502,320.180695 250.114822,318.830627 248.348495,317.999664 
	C245.622604,316.717224 244.045319,318.326752 242.409943,321.037476 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M291.297668,320.284241 
	C291.251587,327.787231 288.860565,331.957092 283.714508,333.972321 
	C277.454590,336.423828 270.018402,335.137115 266.041382,330.914368 
	C262.535004,327.191345 261.651947,321.390289 263.844391,316.481598 
	C266.062531,311.515381 272.219025,308.279449 278.371796,308.845825 
	C285.641357,309.515045 289.427094,312.743744 291.297668,320.284241 
M272.565399,323.039215 
	C273.842163,325.353088 275.459991,327.156342 278.416504,326.103424 
	C280.429413,325.386566 281.754822,323.916260 281.543091,321.630432 
	C281.331482,319.345795 279.681213,317.959686 277.658081,317.790161 
	C274.641876,317.537445 272.715790,319.146881 272.565399,323.039215 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M407.225403,333.337402 
	C398.638763,335.469543 397.804779,334.847565 397.783264,326.875397 
	C397.778809,325.215088 397.777740,323.554382 397.739624,321.894684 
	C397.695312,319.964447 397.351501,317.926392 395.017212,317.856476 
	C392.588409,317.783722 391.631348,319.802795 391.452942,321.887756 
	C391.227142,324.526917 391.174011,327.207458 391.357971,329.848480 
	C391.605255,333.399078 390.006989,334.469727 386.658386,334.410309 
	C383.567169,334.355377 381.539795,333.861725 381.664642,330.085999 
	C381.845581,324.612762 381.800354,319.126495 381.663757,313.650482 
	C381.587341,310.587402 382.953888,309.191803 385.827698,309.743469 
	C389.353821,310.420319 392.617432,309.662781 396.036774,308.991241 
	C400.976044,308.021179 406.251831,311.432037 407.073364,316.159088 
	C408.035522,321.695465 408.684906,327.333191 407.225403,333.337402 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M295.924866,325.222595 
	C298.686646,324.416382 297.284302,323.508057 296.404877,322.461487 
	C291.634369,316.784454 293.373322,311.489502 300.425873,309.455444 
	C304.714783,308.218475 308.719727,308.715576 312.372040,310.954773 
	C316.572266,313.529907 316.495514,316.907806 312.440308,319.562256 
	C312.631470,321.211456 314.365051,321.665253 315.100616,322.920258 
	C317.025665,326.204773 316.770905,329.343536 314.315063,332.106964 
	C309.928101,337.043396 298.179749,336.022705 294.762817,330.446075 
	C293.646973,328.624878 292.297974,326.489899 295.924866,325.222595 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M322.647888,327.926239 
	C322.328308,325.543884 322.030701,323.575500 321.869720,321.595947 
	C321.721344,319.771301 321.694946,318.301849 319.556549,317.166595 
	C316.319611,315.448120 316.705963,311.069336 320.028046,309.658356 
	C321.829620,308.893127 323.399475,308.106445 323.627136,305.996063 
	C323.943848,303.060120 325.924103,302.780396 328.278656,302.742798 
	C331.068878,302.698303 332.163818,304.134613 332.220734,306.717926 
	C332.259003,308.454437 333.027985,309.596893 334.889771,309.646393 
	C337.339996,309.711548 337.920319,311.273651 337.935913,313.318909 
	C337.952728,315.519440 337.467499,317.279877 334.739044,317.406403 
	C332.497559,317.510376 332.172424,319.162964 332.196899,320.954193 
	C332.224548,322.976776 332.134918,325.276794 334.819336,325.599640 
	C338.823456,326.081238 338.356476,328.902100 338.187469,331.545471 
	C337.952576,335.219818 335.030792,334.187592 332.899872,334.339111 
	C328.163757,334.675781 324.305878,333.406647 322.647888,327.926239 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M357.048920,334.355896 
	C353.500183,334.626495 351.962646,333.406342 351.995667,330.120117 
	C352.082581,321.479584 352.116913,312.835907 351.971069,304.196838 
	C351.904541,300.258331 353.929474,299.620361 357.284882,299.587677 
	C360.851227,299.552979 362.393463,300.643829 362.305695,304.426727 
	C362.109192,312.897522 362.132416,321.377838 362.287048,329.850250 
	C362.351746,333.395355 360.839081,334.689331 357.048920,334.355896 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M375.605194,310.065674 
	C377.996490,317.442444 376.984070,324.744720 376.671783,331.971619 
	C376.522583,335.424377 373.172852,334.149933 371.058502,334.307251 
	C368.993378,334.460907 366.860870,334.286407 366.846100,331.413422 
	C366.813629,325.096222 366.282043,318.707916 367.049194,312.483582 
	C367.667999,307.463165 372.281097,310.298615 375.605194,310.065674 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M287.287048,359.570068 
	C293.034180,360.920776 293.641174,362.462646 292.072998,370.798767 
	C285.103516,372.249664 281.670105,371.252838 281.288422,367.667694 
	C280.966064,364.639709 284.264648,359.904053 287.287048,359.570068 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M218.284119,357.743866 
	C221.268982,361.257446 223.255630,359.149323 225.506775,356.136230 
	C226.742523,361.783142 228.066971,366.515900 225.287338,371.703888 
	C221.208511,368.972290 225.190903,364.446045 222.080063,362.096222 
	C217.645447,363.505524 217.524261,365.365662 221.613525,370.589783 
	C221.379974,370.802856 221.177002,371.129211 220.896744,371.221954 
	C218.055466,372.161957 216.471573,371.013580 215.827362,368.190308 
	C213.888428,359.692780 214.056412,358.820343 218.284119,357.743866 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M342.637024,369.526917 
	C341.112091,364.446350 344.584167,363.686951 347.939880,362.691589 
	C346.772430,361.434052 344.627289,363.072449 343.675110,360.902374 
	C346.070251,358.931183 348.925171,358.958221 351.196228,360.472443 
	C355.222504,363.156891 353.060028,367.236633 353.166931,371.128876 
	C349.373962,371.121338 345.923828,372.436340 342.637024,369.526917 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M277.080597,366.934937 
	C276.864044,364.788513 277.002502,362.829956 274.673218,362.060822 
	C270.673676,363.679352 273.731842,368.307068 270.944458,370.765381 
	C269.166901,369.443756 269.888184,367.512848 269.629059,365.912415 
	C269.388519,364.426636 269.273010,362.625732 267.371399,362.577026 
	C265.380524,362.526062 264.677704,364.144653 264.411499,365.934357 
	C264.175110,367.523438 264.899048,369.260132 263.261932,371.278320 
	C261.080902,367.705017 261.648224,364.234863 262.525879,360.900238 
	C262.885529,359.533752 275.279388,359.011597 277.403290,360.078491 
	C279.806458,361.285614 280.290985,365.629059 278.578217,370.937439 
	C276.481293,370.363007 277.375336,368.581757 277.080597,366.934937 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M200.096863,368.746216 
	C200.141235,366.982849 200.056198,365.625183 200.297531,364.328278 
	C200.635071,362.514191 200.098083,359.934418 203.092651,359.869812 
	C206.296997,359.800629 209.938126,358.496277 212.135498,362.188965 
	C213.648422,364.731445 213.377365,367.305511 211.613327,369.667328 
	C210.893280,370.631348 209.855301,371.399078 208.647385,371.281403 
	C205.644501,370.988861 203.614746,372.283264 202.331299,375.171539 
	C199.862137,373.510834 200.595673,371.120819 200.096863,368.746216 
M207.899185,361.936066 
	C204.620422,361.717743 203.500275,363.793976 203.769104,366.479523 
	C203.929993,368.086792 205.362137,369.530182 207.303207,369.052551 
	C209.464401,368.520752 210.636246,366.939178 210.038116,364.637573 
	C209.810593,363.762115 208.998108,363.038696 207.899185,361.936066 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M150.499908,370.285400 
	C150.643707,365.419128 149.838501,360.787567 151.541473,355.595001 
	C153.493668,358.785736 155.947693,359.758240 159.167023,359.969421 
	C161.938583,360.151184 163.296036,362.825989 163.193207,365.436981 
	C163.087463,368.121948 162.065842,370.930298 158.826508,371.182526 
	C156.138107,371.391846 153.277069,372.230713 150.499908,370.285400 
M159.011063,368.020508 
	C160.454468,365.960144 160.282272,364.018494 158.438904,362.333282 
	C157.452591,361.431610 156.316650,361.538391 155.359421,362.471619 
	C154.161072,363.639984 152.931808,364.987762 153.781479,366.748199 
	C154.714905,368.682098 156.219437,369.897491 159.011063,368.020508 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M377.287354,301.799988 
	C376.462433,306.320190 373.559570,307.024536 370.128876,306.304962 
	C366.958130,305.639954 365.994354,303.216125 366.420990,300.245209 
	C366.822998,297.445831 368.395996,295.757599 371.401184,295.711914 
	C375.252716,295.653381 377.305389,297.446289 377.287354,301.799988 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M296.909607,370.599426 
	C292.249268,364.989716 293.714172,360.514465 300.393707,359.757843 
	C303.093292,359.452118 303.542175,357.168152 306.018890,355.534454 
	C306.449188,360.324188 307.071747,364.558167 306.671295,368.900940 
	C306.465698,371.130127 304.930328,371.379791 303.115997,371.319458 
	C301.148438,371.253998 299.147675,371.670197 296.909607,370.599426 
M302.747284,363.018707 
	C300.913544,360.985962 299.204254,361.664429 297.671814,363.358215 
	C296.582367,364.562347 296.143951,365.956909 297.359100,367.392853 
	C298.427155,368.655029 299.792511,369.284424 301.344818,368.540924 
	C303.477264,367.519623 304.121521,365.802917 302.747284,363.018707 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M339.514282,366.329620 
	C336.945312,366.678284 334.522919,365.757385 332.369110,367.559631 
	C333.960602,371.135834 336.923920,368.617981 339.286316,369.647156 
	C337.152283,372.139130 334.176025,371.766968 331.611206,370.701782 
	C328.208862,369.288818 328.065186,366.022034 329.076996,363.042328 
	C330.386719,359.185150 333.885223,359.134979 336.977112,359.936493 
	C339.990509,360.717651 341.767609,362.823730 339.514282,366.329620 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M314.990234,366.404602 
	C313.701843,366.645569 312.573456,366.552307 312.307465,367.918213 
	C313.972504,371.184784 316.685638,368.571716 319.676849,369.098358 
	C317.758789,372.376862 314.916382,371.399872 312.775848,371.164490 
	C310.045959,370.864288 308.342529,369.044250 308.480896,366.030243 
	C308.609222,363.235809 309.189301,360.681519 312.396912,359.872101 
	C313.185791,359.673065 314.045929,359.708984 314.872650,359.716248 
	C317.652161,359.740723 320.008667,360.696625 320.128876,363.785980 
	C320.267090,367.337830 317.233215,366.014923 314.990234,366.404602 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M236.556122,371.212280 
	C233.626190,371.458282 231.054108,371.655762 229.300964,369.338745 
	C227.341492,366.749084 227.360931,363.895416 229.463211,361.565704 
	C232.059967,358.687988 235.572800,358.928345 238.572830,360.676758 
	C241.449692,362.353455 241.399582,365.321991 240.195251,368.193237 
	C239.587387,369.642487 238.418976,370.560852 236.556122,371.212280 
M236.673096,368.238373 
	C238.630432,366.389008 238.680756,364.499542 236.652252,362.713318 
	C235.127869,361.370941 233.597549,361.845306 232.151749,362.973541 
	C230.873383,363.971191 229.949982,365.183655 230.859680,366.799408 
	C232.035507,368.887848 233.665497,370.127228 236.673096,368.238373 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M134.966125,369.321808 
	C132.132797,372.132507 129.457169,371.893066 126.480095,370.168335 
	C127.611115,368.201569 128.814438,369.470276 129.841370,369.364899 
	C131.143387,369.231354 132.111176,368.636993 132.365280,367.350922 
	C132.583557,366.246063 131.827255,365.490265 130.958435,364.918854 
	C130.544022,364.646332 130.078094,364.452606 129.637878,364.218719 
	C126.945877,362.788269 124.422592,360.912933 126.506218,357.629974 
	C128.637802,354.271393 131.740372,355.724213 134.978836,356.442535 
	C133.479507,359.214172 130.407242,356.463715 129.479691,359.711945 
	C130.569519,362.929077 137.406097,363.381073 134.966125,369.321808 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M138.947449,370.195312 
	C136.282623,366.873657 137.509674,363.705963 138.050842,360.040039 
	C140.500824,361.877899 139.738663,364.245758 140.329132,366.130829 
	C140.727264,367.401825 141.066864,368.877716 142.657639,368.981537 
	C144.468430,369.099670 145.120056,367.644836 145.647873,366.183960 
	C146.345276,364.253662 145.408432,361.850159 147.659851,359.900299 
	C149.104614,363.570618 148.348007,367.054291 148.612274,370.710022 
	C145.188019,371.471771 142.201492,372.031372 138.947449,370.195312 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M187.163727,362.769287 
	C187.438782,358.990814 190.073776,359.674805 192.057358,359.739410 
	C194.514389,359.819397 196.611008,359.616882 198.007187,357.026947 
	C198.007187,361.703857 198.007187,366.380768 198.007187,371.810944 
	C195.294739,369.406403 196.691269,367.174805 196.557053,365.206848 
	C196.445145,363.566132 196.853897,361.746490 194.355698,361.527771 
	C192.129623,361.332886 190.921112,362.455841 190.385910,364.454712 
	C189.818237,366.574768 190.882828,369.110779 188.703522,371.190430 
	C186.212738,368.699646 188.271393,365.611145 187.163727,362.769287 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M246.988190,362.629517 
	C244.258545,365.036957 246.042770,368.629272 243.703506,372.417603 
	C241.206299,367.675293 242.065582,364.020935 242.935623,360.071075 
	C246.193634,359.870728 249.804367,358.195282 252.232971,361.226166 
	C254.518845,364.078949 253.268158,367.631317 252.942108,371.994751 
	C250.074081,368.368408 254.123306,362.126465 246.988190,362.629517 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M372.442322,363.966888 
	C373.263428,362.428406 373.420837,360.776215 376.051270,360.032227 
	C374.876190,365.758392 372.757660,370.477203 369.371338,375.009583 
	C370.159729,369.531097 366.326263,365.319672 365.252777,360.009949 
	C368.493347,360.477966 368.480560,362.670074 369.256561,364.130890 
	C370.305145,366.104797 371.205963,366.169678 372.442322,363.966888 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M172.230042,365.716187 
	C173.758667,367.446320 173.918701,369.086975 172.347794,370.325531 
	C170.209076,372.011841 167.780380,371.687347 165.304520,370.870300 
	C165.865265,368.469604 168.697342,370.336090 169.365677,367.907227 
	C168.751190,365.410828 162.070404,365.539398 165.243286,361.033508 
	C166.931259,358.636322 169.886948,359.365875 172.466080,360.382935 
	C171.822449,363.013580 169.061310,360.364105 168.425064,362.588348 
	C168.640762,364.607269 170.821548,364.452759 172.230042,365.716187 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M179.523621,362.571045 
	C177.023849,366.567596 177.023849,366.567596 186.103058,369.874451 
	C182.742752,372.218964 179.649719,372.199371 176.956009,369.963074 
	C174.327057,367.780548 174.046310,364.637787 176.130295,361.921722 
	C178.660858,358.623627 181.897491,359.179932 185.597641,360.872375 
	C183.656418,363.037323 181.245453,360.622650 179.523621,362.571045 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M357.223572,365.674011 
	C355.418427,364.193939 354.093445,362.551880 355.856201,360.886932 
	C357.950989,358.908356 360.553284,359.275085 363.084290,360.371735 
	C362.412048,362.811157 360.101746,360.836487 359.072235,362.685455 
	C359.711914,365.268097 365.821106,365.704834 362.955200,369.989441 
	C361.100372,372.762482 358.340210,370.962860 355.459534,371.223114 
	C356.788605,368.651093 359.171295,370.423462 360.213898,368.375153 
	C360.669708,366.486420 358.694916,366.542297 357.223572,365.674011 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M205.871704,191.505676 
	C207.010071,187.711853 209.633652,185.642410 212.310410,183.637421 
	C222.821579,175.764236 233.341293,167.902420 243.866165,160.047562 
	C248.400925,156.663208 253.560257,156.618988 258.124847,160.018097 
	C269.457855,168.457397 280.770294,176.924408 292.065582,185.414154 
	C296.665833,188.871796 297.811584,195.280533 294.679260,199.532333 
	C291.705566,203.568817 285.547791,204.268723 280.854095,200.827789 
	C270.956573,193.571960 261.161560,186.176285 251.137726,178.698700 
	C241.463043,185.957016 232.041382,193.030090 222.613937,200.095428 
	C219.899811,202.129532 217.045166,203.791794 213.446930,202.960571 
	C207.912857,201.682144 205.762955,198.556152 205.871704,191.505676 
z"/>
          <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M274.987152,215.950348 
	C277.763855,217.826401 280.271698,219.459229 282.695038,221.209000 
	C287.553253,224.716858 288.895264,230.667068 285.913025,235.168640 
	C283.044739,239.498230 277.438782,240.507767 272.233612,237.256302 
	C266.312439,233.557571 260.505585,229.660599 254.810654,225.621857 
	C252.247910,223.804367 250.240112,223.745316 247.649033,225.572708 
	C241.813446,229.688385 235.866760,233.661667 229.809097,237.442657 
	C224.771454,240.586975 219.554840,239.659744 216.587067,235.492645 
	C213.244858,230.799759 214.051208,225.153183 219.222031,221.522202 
	C227.792267,215.504089 236.543015,209.729004 245.397400,204.135971 
	C249.327026,201.653763 253.615875,201.882004 257.615387,204.522095 
	C263.299530,208.274231 268.991486,212.014618 274.987152,215.950348 
z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
